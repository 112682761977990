@font-face {
  font-family: "NeoSansPro";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/neo-sans-pro/NeoSansPro-Regular.woff") format("woff");
}

@font-face {
  font-family: "NeoSansPro";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/neo-sans-pro/NeoSansPro-Medium.woff") format("woff");
}

@font-face {
  font-family: "NeoSansPro";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/neo-sans-pro/NeoSansPro-Bold.woff") format("woff");
}
